.claims-action-modal {
    font-size: 14px;
    z-index: 130000000;
  
    .modal-container {
      border-radius: 8px;
      padding: 16px;
    }
  
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .modal-title {
      font-weight: bold;
      font-size: 1.25rem;
      padding: 0px 20px;
    }
  
    .close-button {
      color: #333;
    }
  
    .modal-divider {
      margin: 10px -15px 20px -15px;
      border-color: #8b8585;
    }
  
    .refund-type {
      font-weight: 500;
      margin-bottom:14px;
      display:flex;
      flex-direction: row;

      .lable{
        font-size: 16px;
        font-weight:400;
      }

      .value{
        font-size: 16px;
        font-weight:500;
        color: #3e4242;
      }

    }
  
    .comment-box {
      margin-bottom: 16px;
  
      .comment-input {
        font-size: 14px;
  
        .MuiOutlinedInput-root {
          font-size: 14px;
        }
      }
    }
  
    .modal-actions {
      display: flex;
      justify-content: space-between;
      padding: 16px;
  
      .cancel-button {
        border: 1px solid #f0ecec;
        color: #f44336;
        padding: 8px;
        font-size: 14px;
        text-transform: capitalize;
        width: 48%;
      }
  
      .submit-button {
        background-color: #00b4d7;
        color: #fff;
        padding: 8px;
        font-size: 14px;
        text-transform: capitalize;
        width: 48%;
      }
  
      .reject-button {
        background-color: #795d5d;
      }
    }
  
    // Mobile responsive styles
    @media (max-width: 600px) {
      .modal-container {
        padding: 12px;
      }
  
      .modal-title {
        font-size: 1.1rem;
      }
  
      .modal-actions {
        flex-direction: column;
  
        .cancel-button,
        .submit-button {
          width: 100%;
          margin-bottom: 8px;
        }
      }
  
      .comment-box {
        .comment-input {
          font-size: 12px;
        }
      }
    }
  }
  