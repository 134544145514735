$theme_primary_clr: #00B4D7;
$gray_clr: #474646;
$black: #000;
$border_clr: #EAE9E9;
$white: #F8F7F7;
$title_clr: #202020;
$placeholder_clr: #A09595;
$data_clr: #353535;

.claim-details-page {
    margin-top: 35px;

    .heading-sec {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        h4 {
            color: $gray_clr;
            font-size: 18px;
            line-height: 27px;
            font-weight: 700;
            margin: 0px;
            margin-bottom: 10px;
        }

        p {
            color: $black;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            margin: 0px;
        }

        .close-btn {
            button {
                margin-bottom: 0px;
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
        }

        .fill-primary-btn {
            background: $theme_primary_clr;
            color: $white;
            padding: 12px 24px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 700;

            .MuiTouchRipple-root {
                display: none;
            }
        }
    }

    .content-sec {
        background-color: $white;
        margin-bottom: 33px;

        .claim-form-heading {
            padding: 27px 20px 0px;
            // border-bottom: 1px solid $border_clr;
            // margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            h3 {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: $title_clr;
            }

            button {
                background-color: transparent;
                color: $black;
                padding: 0px;
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: 10px;
                text-transform: none;

                svg {
                    max-width: 16px;
                    max-height: 16px;
                }

                .MuiTouchRipple-root {
                    display: none;
                }
            }
        }

        .claims-details-main-box {
            padding: 27px 20px 10px;

            .claims-detl-box {
                display: flex;
                align-items: center;
                gap: 17px;
                padding-bottom: 22px;
                border-bottom: 1px solid $border_clr;
                margin-bottom: 22px;

                &:last-child {
                    border-bottom: none;    
                    margin-bottom: 0;  
                  }

                .claims-detl {
                    height: 100%;
                    width: 100%;

                    .title {
                        color: $theme_primary_clr;
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 500;
                        margin: 0px;
                        margin-bottom: 10px;
                    }

                    h4 {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 400;
                        margin: 0px;
                        // color: $data_clr;
                        max-width: 862px;
                        margin-bottom: 9px;

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }

                    .MuiTypography-root {
                        font-size: 14px;
                      }
            
                      .MuiTypography-caption {
                        font-size: 12px;
                      }
                }
            }
        }
    }

    .claims-details-feedbacks {
        padding: 30px 20px 10px;
        background-color: $white;

        .claims-detl-feed-head {
            margin-bottom: 20px;

            h4 {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: $title_clr;
                margin: 0px;
                margin-bottom: 7px;
            }

            p {
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                color: $black;
                margin: 0px;
            }
        }

        .feedback-form {
            margin-bottom: 35px;

            textarea {
                width: 100%;
                height: 136px !important;
                border-color: $border_clr;
                padding: 22px 25px;
                font-family: "popping", sans-serif;
                color: $placeholder_clr;
                outline: none;
            }
        }

        .submit-btn {
            button {
                background-color: $theme_primary_clr;
                color: $white;
                padding: 14px 45px;
                font-size: 14px;
                line-height: 21px;
                font-weight: 700;
                text-transform: uppercase;
                width: 100%;
                max-width: 200px;
                border: 0px;

                .MuiTouchRipple-root {
                    display: none;
                }
            }
        }
    }

    .chat-box {
        background-color: $white;

        .chat-heading-sec{
h4 {
            color: $gray_clr;
            font-size: 18px;
            line-height: 27px;
            font-weight: 700;
            margin: 0px;
        }
        }

        .chat-body {
            display: flex;
            flex-direction: column;
            gap: 1px;
            max-height: 400px;
            overflow-y: auto;
            padding: 10px;

            .chat-message {
                display: flex;
                flex-direction: column;
                max-width: 75%;
                margin-bottom: 12px;
                line-height: 1.4;

                &.sent {
                    align-self: flex-end;
                    background: #00b4d7;
                    color: #fff;
                    border-radius: 15px 15px 0 15px;
                    padding: 10px 15px;
                }

                &.received {
                    align-self: flex-start;
                    background: #dfd4d4;
                    color: #2f2727;
                    border-radius: 15px 15px 15px 0;
                    padding: 10px 15px;
                }

                .message-bubble {
                    display: flex;
                    flex-direction: column;
                    word-wrap: break-word;

                    .MuiTypography-root {
                        font-size: 14px;
                    }

                    .MuiTypography-caption {
                        font-size: 12px;
                    }

                    
                    .message-status {
                        margin-left: 4px;
                        display: inline-flex;
                      }
                      
                      .read-icon {
                        color: #684ff7; 
                      }
                      
                      .unread-icon {
                        color: rgba(0, 0, 0, 0.45);
                      }
                }

                .timestamp {
                    font-size: 12px;
                    margin-top: 3px;
                    
                    &.sent {
                        color:#fff ;
                        align-self: flex-end;
                    }

                    &.received {
                        color: #888;
                        align-self: flex-start;
                    }
                   
                }
            }
        }

        .empty-chat {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;                     
            text-align: center;
            color: #888;                      
            font-size: 18px;                  
            font-weight: 500;                 
            padding: 20px;
            opacity: 0.8;                     
          
            p {
              margin: 0;
              padding: 10px 20px;                 
            }
          
          }

          .chat-footer {
            display: flex;
            gap:5px;
            align-items: center;
            padding: 10px 15px;
            background: #f0f0f0;
            border-top: 1px solid #ccc;
          
            .chat-input {
              height: 50px;        
              border-radius: 8px;
          
              input {
                font-size: 14px;        
                letter-spacing: 0.5px;  
              }
          
              ::placeholder {
                color: #aaa;
              }
            }
          
            .chat-button {
              height: 50px;
              min-width: 120px;
              font-size: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 2px;
                    
              &:disabled {
                background-color: #ccc;
                cursor: not-allowed;
              }
            }
          }
          

        .chat-body::-webkit-scrollbar {
            width: 0px;
        }

        // .chat-body::-webkit-scrollbar-thumb {
        //     background: #ccc;
        //     border-radius: 10px;
        // }

        // .chat-body::-webkit-scrollbar-thumb:hover {
        //     background: #00b4d7;
        // }

        @media (max-width: 600px) {
            .chat-body {
                max-height: 300px;
            }
            
        }
    }
}

@media (max-width: 575px) {
    .claim-details-page {
        .heading-sec {
            align-items: flex-start;
            margin-bottom: 20px;

            h4 {
                font-size: 14px;
                line-height: 18px;
                margin-top: 2px;
            }

            p {
                font-size: 12px;
                line-height: 16px;
            }

            .fill-primary-btn {
                font-size: 12px;
                line-height: 16px;
                padding: 9px 14px;
                width: 100%;
                min-width: 135px;
            }
        }

        .chat-box{
            .chat-heading-sec{
                h4 {
                    font-size: 14px;
                    line-height: 18px;
                    margin-top: 2px;
                }
        }
    }

        .content-sec {
            .claim-form-heading {
                padding: 17px 8px 0px;

                h3 {
                    font-size: 12px;
                    line-height: 18px;
                }

                button {
                    font-size: 12px;
                    line-height: 15px;
                    gap: 6px;
                    min-width: 102px;
                }
            }

            .claims-details-main-box {
                padding: 17px 8px 10px;

                .claims-detl-box {
                    gap: 9px;
                    flex-wrap: wrap;

                    .claims-detl {
                        .title {
                            font-size: 12px;
                            line-height: 15px;
                        }

                        h4 {
                            font-size: 12px;
                            line-height: 15px;
                        }

                        .MuiTypography-root {
                            font-size: 12px;
                          }
                
                          .MuiTypography-caption {
                            font-size: 10px;
                          }
                    }
                }
            }
        }

        .claims-details-feedbacks {
            padding: 15px 8px 10px;

            .claims-detl-feed-head {
                h4 {
                    font-size: 12px;
                    line-height: 18px;
                }

                p {
                    font-size: 12px;
                    line-height: 15px;
                }
            }

            .feedback-form {
                margin-bottom: 21px;

                textarea {
                    padding: 16px 9px;
                }
            }

            .submit-btn {
                button {
                    max-width: 100%;
                }
            }
        }

        .chat-box {
            .chat-body {
                .chat-message {
                    .message-bubble {
                        display: flex;
                        flex-direction: column;
                        word-wrap: break-word;

                        .MuiTypography-root {
                            font-size: 13px;
                        }

                        .MuiTypography-caption {
                            font-size: 10px;
                        }
                    }
                }

                .empty-chat {                    
                    font-size: 14px;                                     
                  
                    p {
                      margin: 0;
                      padding: 10px 20px;                 
                    }
                  
                  }
            }

            .chat-footer {
                display: flex;
                align-items: center;
              
                .chat-input {
                  height: 52px;        
                  border-radius: 8px;
              
                  input {
                    font-size: 14px;        
                    letter-spacing: 0.5px;  
                  }
              
                  ::placeholder {
                    color: #aaa;
                  }
                }
              
                .chat-button {
                  height:50px;
                  min-width: 90px;
                  font-size: 14px;                        
                }
              }
        }
    }
}